import React, {useContext} from "react";
import {
  Form,
  Input,
  SelectArray,
  Media,
  Static,
  Fields, Container
} from "../../components/Form/Form";
import countryCodes from "../../data/countryCodes";
import useCompanyService from "../../api/services/useCompanyService";
import {useNavigate, useParams} from "react-router-dom";
import useForm from "../../hooks/useForm";
import IbanMedia from "../../components/Form/IbanMedia";
import CompanyUsers from "../../components/Form/CompanyUsers";
import Address from "../../components/Form/Address";
import AuthContext from "../../context/AuthContext";
import companyTypeEnum from "../../data/companyTypeEnum";

const UserCompanyEdit = () => {

  //todo er is een error als je geen companies hebt, 2 toevoegd, en dan 2 verwijderd. (mss afbeelding; default afbeelding of demo data afbeelding error. Check even)
  const {id} = useParams();
  const companyService = useCompanyService()
  const form = useForm()
  const navigate = useNavigate()
  const {user} = useContext(AuthContext)

  return (
    <div className="page-user-company">
      <h1>Organisatie Instellingen</h1>
      <Form>
        <Fields
          id={id}
          form={form}
          apiService={companyService}
          GAEventLabel="User Company Edit"
          showDelete={(user.is_admin)}
          deleteLabel="Alleen voor Standplaats admins: Verwijder bedrijf, gekoppelde stands/locaties, de koppeling tussen bedrijf en gebruikers, en alle verstuurd/ontvangen berichten (definitief)"
          deleteSuccess={() => navigate('/user/organisaties')}
        >
          <Media
            label="Logo"
            name="logo_media"
            width={120}
            height={120}
            size="logo"
          />
          <Static
            label="Standplaats Credits"
            name="credits"
          />
          <h3>Type account, organisatie / bedrijfsgegevens & KVK</h3>
          {/*<Verified*/}
          {/*  label="Bedrijfsnaam"*/}
          {/*  name="legal_name_verified_at"*/}
          {/*  validateButtonOnClick={onClickValidate}*/}
          {/*  validateButtonLabel={"nu valideren via iDeal"}*/}
          {/*/>*/}

          <SelectArray
            label="Type account/organisatie/bedrijf"
            name="type"
            data={companyTypeEnum}
          />

          {/*Conditional show legal_name field*/}
          <Input
            label="Naam"
            name="legal_name"
            placeholder="Voorbeeld: De groentebroers"
            enabledBy={[{key: 'type', value: 'non-official'}]}
          />
          <Input
            label="Officiële bedrijfs naam"
            name="legal_name"
            placeholder="Voorbeeld: Standplaats.nl BV"
            enabledBy={[{key: 'type', value: 'commercial'}]}
          />
          <Input
            label="Organisatie naam"
            name="legal_name"
            placeholder="Voorbeeld: Gemeente Utrecht"
            enabledBy={[{key: 'type', value: 'public'}]}
          />
          <Input
            label="Organisatie naam"
            name="legal_name"
            placeholder="Voorbeeld: Stichting Verse groente"
            enabledBy={[{key: 'type', value: 'non-profit'}]}
          />
          {/* end conditional show legal_name field*/}


          <Input
            label="KVK Nummer (Alleen invullen als deze organisatie ingeschreven staat bij de KVK)"
            name="coc_number"
            disabledByKeys={[{key: 'type', value: 'non-official'}]}
          />
          <SelectArray
            label="Land van inschrijving (KVK)"
            name="country_code"
            data={countryCodes}
            disabledByKeys={[{key: 'type', value: 'non-official'}]}
          />
          {/*<Verified*/}
          {/*  label="KVK & Bedrijfsnaam"*/}
          {/*  name="coc_number_verified_at"*/}
          {/*  validateButtonOnClick={() => {*/}
          {/*  }}*/}
          {/*  validateButtonLabel={"nu valideren"}*/}
          {/*/>*/}

          <Container
            disabledByKeys={[{key: 'type', value: 'non-official'}]}
          >
            <>
              <hr/>
              <h3>Adres</h3>
            </>
          </Container>

          <Address
            text="Het adres waar je organisatie berijkbaar is."
            name="address"
            disabledByKeys={[{key: 'type', value: 'non-official'}]}
          />

          <Container
            disabledByKeys={[{key: 'type', value: 'non-official'}]}
          >
            <>
              <hr/>
              <h3>BTW</h3>
            </>
          </Container>

          {/*<Verified*/}
          {/*  label="BTW nummer"*/}
          {/*  name="vat_number_verified_at"*/}
          {/*  validateButtonOnClick={}*/}
          {/*  validateButtonLabel={"nu valideren"}*/}
          {/*/>*/}
          <Input
            label="BTW Nummer (alleen invullen als deze organisatie BTW plichtig is)"
            name="vat_number"
            disabledByKeys={[{key: 'type', value: 'non-official'}]}
          />

          <Container
            disabledByKeys={[{key: 'type', value: 'non-official'}]}
          >
            <>
              <hr/>
              <h3>Contactgegevens </h3>
            </>
          </Container>


          {/*<Verified*/}
          {/*  label="Telefoon"*/}
          {/*  name="phone_verified_at"*/}
          {/*  validateButtonOnClick={}*/}
          {/*  validateButtonLabel={"nu valideren"}*/}
          {/*/>*/}
          <Input
            label="Telefoonnummer"
            name="phone"
            disabledByKeys={[{key: 'type', value: 'non-official'}]}
          />

          {/*Adres toevoegen*/}
          <Container
            disabledByKeys={[{key: 'type', value: 'non-official'}]}
          >
            <>
              <hr/>
              <h3>Bankgegevens voor te ontvangen inkomsten</h3>
            </>
          </Container>


          <IbanMedia
            name="iban_media"
            width={900}
            size="normal_full_height"
            id={id}
            disabledByKeys={[{key: 'type', value: 'non-official'}]}
          />
          <hr/>

          <CompanyUsers/>
        </Fields>
      </Form>
    </div>
  )
}

export default UserCompanyEdit
